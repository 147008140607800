/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'src/pages/styles';

.faq-container {
  @include styles.content-container;

  .faq-questions {
    padding-bottom: 30px;

    .MuiTypography-h2 {
      @include styles.title;
    }

    ul {
      margin-top: 0;
    }

    li:not(:first-child) {
      padding-top: 12px;
    }
  }

}
