/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

$screen-max-width: 850px;

@mixin content-container() {
  margin: auto;
  padding-bottom: 20px;
  width: calc($screen-max-width - 30px);

  @media only screen and (max-width: $screen-max-width) {
    box-sizing: border-box;
    padding: 10px 30px 20px 30px;
    width: 100%;
  }
}

@mixin title() {
  margin: 50px 0 30px 0;
}

@mixin subheader() {
  margin: 30px 0 15px 0;
}

@mixin section() {
  margin-top: 15px;
}

@mixin footnote() {
  margin-top: 50px;
}

// Global accessibility styles.
.screen-reader-only {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -10000px;
  left: -10000px;
}

.MuiButtonBase-root:focus {
  outline: 2px solid transparent;
  z-index: 10;
}