/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
 @use 'src/pages/styles';

 .action-card {
    flex-basis: 100%;
    display: flex;
    max-width: 350px;
    min-height: 140px;
    margin: 10px;
  }

  .action-card-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .action-icon {
    margin-left: 8px;
    text-align: right;
  }