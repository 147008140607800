/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'src/pages/styles';

.governance-container {
  @include styles.content-container;

  .title {
    @include styles.title;
  }

  .MuiTypography-h4,
  .MuiTypography-h5 {
    @include styles.subheader;
  }

  .section {
    @include styles.section;
  }

  .MuiButton-outlinedPrimary {
    margin-top: 20px;
  }

  .advisory-list,
  .secretariat-list {
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  .card {
    width: 355px;
    padding: 20px;

    @media only screen and (max-width: styles.$screen-max-width) {
      width: 100%;
    }
  }

  .memo {
    margin: 8px 0 24px;
  }

  .memo-description {
    margin: 8px 0;
  }

  .footnote {
    margin-top: 25px;
    margin-bottom: 40px;
  }
}