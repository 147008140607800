/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'src/pages/styles';

.table-container {
  @include styles.section;
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);

  .MuiTable-root {
    height: fit-content;
  }

  .MuiTableRow-root {
    height: 100%;
  }

  .MuiTableCell-root {
    font-size: medium;
    padding: 8px 16px;

    &:first-child {
      padding-left: 0;
      width: 48px;
    }
  }

  .row-with-subrows {
    .MuiTableCell-root {
      padding: 0px 8px;
      border: none;
    }

    .row-with-subrows-data-cell {
      text-align: left;
      padding: 0px 16px;
    }

    .row-with-subrows-icon-cell {
      &.MuiButtonBase-root {
        padding: 8px 0px;
      }

      .row-with-subrows-icon {
        font-size: 22px;

        &.MuiSvgIcon-root {
          border: 1px solid;
          border-radius: 4px;
          border-color: rgb(224, 224, 224);
        }
      }
    }

    .row-with-subrows-name-cell {
      white-space: nowrap;
      padding: 8px 0px 0px 16px;
      min-width: 150px;
    }
  }

  .subrow {
    .MuiTableCell-root {
      padding: 0px;
      border: none;
      height: inherit;
    }

    .subrow-data-cell {
      padding: 0px 16px;
    }

    .subrow-icon-cell {
      .subrow-divider {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .MuiDivider-root {
          border-color:rgb(224, 224, 224);
        }
      }
    }

    .subrow-name-cell {
      padding: 0px 0px 0px 32px;
      white-space: nowrap;
    }
  }
}
