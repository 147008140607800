/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'src/pages/styles';

.output-data-container {
  .output-field-section {
    margin-top: 50px;
  }

  .output-field-error {
    margin: 50px 80px 150px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .output-field-error-text {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .output-field-error .MuiTypography-body2 {
    margin-top: 10px;
  }

  .output-field-model-version {
    @include styles.footnote;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: styles.$screen-max-width) {
    .output-field-error {
      margin: 50px 0px 0px 0px;
    }

    .output-field-error-text {
      flex-direction: column;
    }
  }

}
