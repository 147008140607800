/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'src/pages/styles';

.readonly-input-fields-container {
  margin-bottom: 50px;

  .readonly-flight-container {
    @include styles.section;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 12px 16px;

    .MuiTypography-body2 {
      margin-top: 2px;
    }

    .MuiButtonBase-root {
      margin-left: 16px;
      color: #026DA7;
    }
  }
}
