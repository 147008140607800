/*
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "src/pages/styles";

.flight-input-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 16px 24px 16px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .MuiTextField-root {
    margin-top: 12px;
    flex: 1 1 30%;
  }

  .MuiInputLabel-root {
    color: black;
    background-color: white;
    padding: 0 8px;
    left: -6px;
  }

  .MuiFormHelperText-root {
    color: #333333;
  }

  .MuiInputBase-root {
    outline: 1px solid transparent;

    &.Mui-focused {
      outline: 2px solid transparent;
      z-index: 10;
    }
  }

  .MuiSvgIcon-root {
    font-size: 18px;
  }
}

.MuiDayCalendar-header {
  .MuiDayCalendar-weekDayLabel {
    color: rgba(0, 0, 0, 0.6);
  }
}

.input-box {
  @include styles.section;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-bottom: 50px;
}

.show-emissions-button {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
  margin-bottom: 16px;
}
